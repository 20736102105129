button.cartButton {
  padding: 0 var(--s_2);
  margin: var(--s_3);
  border: 1.5px solid var(--color-bg-transparent);
  display: grid;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus,
  &:focus-within {
    border-color: var(--color-fg);
  }

  &:focus,
  &:focus-within,
  &:active {
    outline: var(--color-fg) solid 1px;
    outline-offset: 1px;

    &:hover {
      outline: none;
    }
  }

  & > * {
    grid-row: 1;
    grid-column: 1;
  }

  & svg {
    height: var(--cart-height);
  }
}
