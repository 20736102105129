@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .utility-content {
    @apply py-s4 px-s3 flex flex-col items-center justify-center text-center gap-s1;
  }
}

:root {
  --space-ratio: 1.4142135624; /* Square root of 2 */

  --s_4: calc(var(--s_3) / var(--space-ratio)); /* 5px */
  --s_3: calc(var(--s_2) / var(--space-ratio)); /* ~7px */
  --s_2: calc(var(--s_1) / var(--space-ratio)); /* 10px */
  --s_1: calc(var(--s0) / var(--space-ratio)); /* ~14px */
  --s0: calc(1rem * 20 / 18); /* 20px */
  --s1: calc(var(--s0) * var(--space-ratio)); /* ~28px */
  --s2: calc(var(--s1) * var(--space-ratio)); /* 40px */
  --s3: calc(var(--s2) * var(--space-ratio)); /* ~57px */
  --s4: calc(var(--s3) * var(--space-ratio)); /* 80px */
  --s5: calc(var(--s4) * var(--space-ratio)); /* ~113px */
  --s6: calc(var(--s5) * var(--space-ratio)); /* 160px */
  --s7: calc(var(--s6) * var(--space-ratio)); /* ~226px */

  --f_2: 12px;
  --f_1: 14px;
  --f0: 18px;
  --f1: 20px;

  --f2: 22px;
  --f3: 25px;
  --f4: 30px; /* unused, historical artifact */
  --f5: 30px;
  --f6: 40px;
  --f7: 40px;

  @media (min-width: 56rem) {
    --f2: 25px;
    --f3: 30px;
    --f4: 40px; /* unused, historical artifact */
    --f5: 40px;
    --f6: 50px;
    --f7: 70px;
  }

  --measure: 60ch;

  --border-thin: 1.5px;

  /** literal color names – avoid if possible */
  --color-navy: #022548;
  --color-navy-80: #022548cc;
  --color-fog: #d0dae3;
  --color-chartreuse: #e4db36;
  --color-olive: #9d9615;
  --color-white: #ffffff;
  --color-pink: #fbeaed;
  --color-red: #cd1d47;
  --color-dark-gray: #444749;
  --color-dark-gray-20: #44474933;
  --color-dark-gray-30: #4447494c;
  --color-black: #000000;
  --color-black-30: #0000004c;
  --color-black-50: #00000080;
  --color-black-00: #00000000; /* transparent, but helps Safari transitions */

  /** functional color names – use these */
  --color-bg-default: var(--color-white);
  --color-text-default: var(--color-navy);
  --color-border-default: var(--color-navy);
  --color-bg-inverse: var(--color-text-default);
  --color-text-inverse: var(--color-bg-default);
  --color-bg-accent: var(--color-chartreuse);
  --color-border-accent: var(--color-olive);
  --color-bg-highlight: var(--color-fog);
  --color-border-lowlight: var(--color-fog);
  --color-bg-error: var(--color-pink);
  --color-text-error: var(--color-red);
  --color-text-fineprint: var(--color-dark-gray);
  --color-shadow: var(--color-black);
  --color-half-shadow: var(--color-black-50);
  --color-bg-transparent: var(--color-black-00);
  --color-bg-translucent: var(--color-dark-gray-30);
  --color-bg-underlay: var(--color-navy-80);

  color: var(--color-text-default);
  background-color: var(--color-bg-default);
  font-family: Archivo, sans-serif;
  font-size: var(--f0);
  line-height: 1.1;
  text-underline-position: from-font;

  --header-height: var(--s4);
  --section-border-width: var(--border-thin);
  --section-border: var(--section-border-width) solid;
  --interaction-border-width: var(--border-thin);
  --interaction-border: var(--interaction-border-width) solid;

  --interaction-transition-duration: 0.3s;

  &.smoothScroll {
    scroll-behavior: smooth;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

/** Prevent link anchors and tabbed-to elements from being hidden behind the header. */
a[href],
button,
input,
textarea,
select,
details,
[tabindex]:not([tabindex='-1']),
[id] {
  scroll-margin-top: calc(var(--header-height) + 1rem);
}

h1 {
  font-size: var(--f6);
  font-weight: 700;
  line-height: calc(2px + 2ex + 2px);

  margin: 0;
}

h2 {
  font-size: var(--f5);
  font-weight: 700;
  line-height: 1.088;
}

h3 {
  font-size: var(--f3);
  font-weight: 700;
}

h4 {
  font-size: var(--f5);
  font-weight: 100;
}

h5 {
  font-size: var(--f2);
  font-weight: 700;
}

h6 {
  font-size: var(--f1);
  font-weight: 700;
}

[role='doc-subtitle'] {
  font-size: var(--f0);
  font-weight: 600;
  letter-spacing: 0.125em;
  text-transform: uppercase;
}

h1.utility-heading {
  font-size: var(--f3);

  & strong {
    font-size: var(--f5);
    display: block;
  }
}

p,
dl,
li {
  max-width: var(--measure);
  line-height: calc(8 / 5);
}

.list-style-none {
  list-style: none;

  &,
  & > li {
    padding: 0;
  }
}

.sr-only {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/**
 * https://cloudfour.com/thinks/breaking-out-with-css-grid-layout/
 */
.content-grid {
  display: grid;
  grid-template-columns:
    [full-start] minmax(1em, 1fr)
    [main-start] minmax(0, 80em) [main-end]
    minmax(1em, 1fr) [full-end];

  & > * {
    grid-column: main;
  }

  & > .content-breakout {
    grid-column: full;
  }
}

/* Low-tech version of Every Layout's Grid component. */
.grid-l {
  display: grid;
  grid-gap: var(--s1);
  align-content: start;
  --grid-min: 250px;
  grid-template-columns: repeat(
    auto-fill,
    minmax(min(var(--grid-min), 100%), 1fr)
  );
}

/* Low-tech version of Every Layout's Stack component. */
.stack-l {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Low-tech version of Every Layout's Switcher component. */
.switcher-l {
  display: flex;
  flex-wrap: wrap;
  --switcher-threshold: var(--measure);

  & > * {
    /* https://heydonworks.com/article/the-flexbox-holy-albatross/ */
    flex: 1 calc((var(--switcher-threshold) - 100%) * 999);
    /**
     * prevents a very strange bug when resizing Safari 16, where
     * elements move around unpredictably and the layout gets "stuck"
     * in mobile mode
     */
    min-width: 0;
  }
}

:where(svg:not([fill])) {
  fill: currentcolor;
}

:where(.link, .with-links :any-link) {
  color: inherit;
  transition-property: font-weight, outline, outline-offset, background-color,
    text-decoration;
  transition-duration: var(--interaction-transition-duration);
  cursor: pointer;

  &:visited {
    color: inherit; /* TODO: visited state */
  }

  &:focus {
    outline: var(--interaction-border);
    outline-offset: 0.125rem;
  }
}

.link {
  text-decoration: transparent underline;

  &:hover,
  &:focus {
    font-weight: 600;
    text-decoration: underline;
  }
}

.with-links :any-link {
  text-decoration: underline;

  &:hover,
  :focus {
    font-weight: 400;
  }

  &:hover {
    background: var(--color-bg-accent);
  }
}

.link-with-icon {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

/**
 * "Smart borders between flex items – the dumb way"
 * https://thatemil.com/blog/2018/01/08/direction-independent-borders-this-is-stupid/
 */
.flex-borders {
  overflow: hidden;

  & > * {
    border-top: 1px solid;
    border-left: 1px solid;
    margin-top: -1px;
    margin-left: -1px;
  }
}

.border-top {
  border-top: 1px solid;
}

.border-bottom {
  border-bottom: 1px solid;
}

/* Button class that strips browser styles,
 * but doesn't add the standard look and feel.
 */
.unbutton {
  appearance: none;
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  font-family: inherit;
  font-size: inherit;

  &:not(.link) {
    /**
     Outlines may be added by individual components.
     (We may generalize this later.)
     */
    transition-property: border, outline, outline-offset, background, color;
    transition-duration: 0.2s;

    &:hover,
    &:focus,
    &:focus-within {
      background: var(--color-bg-highlight);
      color: var(--color-text-default);
    }
  }
}

.button {
  cursor: pointer;
  display: inline-block;
  padding: var(--s0) var(--s1);
  font-family: inherit;
  font-size: var(--f2);
  background: var(--color-bg-default);
  color: var(--color-text-default);
  border: var(--interaction-border);
  text-align: center;

  transition-property: border, outline, outline-offset, background, color;
  transition-duration: var(--interaction-transition-duration);

  &:hover {
    background: var(--color-bg-highlight);
  }

  &:focus,
  &:focus-within {
    background: var(--color-bg-highlight);
  }

  &:focus,
  &:focus-within,
  &:active {
    outline: var(--color-border-default) solid 1px;
    outline-offset: 1px;
  }
}

.button-primary {
  background: var(--color-bg-accent);
  border-color: var(--color-border-accent);

  &:hover,
  &:focus {
    background: var(--color-bg-accent);
    border-color: var(--color-border-default);
  }
}

.button-transparent {
  &:not(:hover, :focus) {
    color: currentcolor;
  }

  outline-color: currentcolor;
  background: var(--color-bg-transparent);
}

.input-text {
  border: var(--interaction-border);
  font-family: inherit;
  font-size: var(--f1);
  padding: var(--s0) var(--s2);
  max-width: 100%;
}

input[type='number'] {
  /* noinspection CssInvalidPropertyValue */
  appearance: textfield; /* hide spinner */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none; /* hide spinner */
}

.color-standard {
  background: var(--color-bg-default);
  color: var(--color-text-default);
}

.color-inverse {
  background: var(--color-bg-inverse);
  color: var(--color-text-inverse);
}

@media screen and (max-width: 1024px) {
  :root {
    --space-ratio: 1.189207115; /* 4th root of 2 */
  }
}
