.header {
  --logo-height: var(--f1);
  --cart-height: var(--s2);
  --color-fg: var(--color-text-default); /* color of text, and button borders */

  @media (min-width: 56rem) {
    --logo-height: var(--f3);
  }

  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
  background: var(--color-bg-default);
  border-bottom: var(--section-border);
  transition: color 1s, background 0.3s, border 0.3s, text-shadow 0.3s;

  &.fixed {
    position: fixed;
  }

  &.foregroundLight {
    --color-fg: var(--color-text-inverse);
  }

  &.foregroundDark {
    --color-fg: var(--color-text-default);
  }

  &.translucent {
    color: var(--color-fg);
    background: var(--color-bg-translucent);
  }

  & a {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -10px;
      right: -10px;
      height: 10px;
      z-index: -1;
      background: transparent;

      transition: background-color 0.3s;
    }
  }
}

.logoLink {
  padding: var(--s_2);

  & svg {
    display: block;
    height: var(--logo-height);
  }
}

.links {
  display: flex;
  align-items: stretch;
  gap: 0;
}

a.shopLink {
  margin: var(--s_3) 0;
  align-self: center;

  padding: calc((var(--s2) - var(--f0)) / 2);
  font-size: var(--f_1);
}

@media (min-width: 48rem) {
  a.shopLink {
    font-size: var(--f1);
  }
}
