.footer {
  & .link {
    font-weight: 600;

    &:hover,
    &:focus {
      font-weight: 700;
    }
  }

  & .shopLink {
    font-size: var(--f3);

    &:hover,
    &:focus {
      font-family: 'Archivo Black', Archivo, sans-serif;
      font-weight: normal;
    }
  }

  & form {
    min-width: 50%;

    font-size: var(--f2);
    font-weight: 600;
    line-height: calc(3ex);
  }
}
